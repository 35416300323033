import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import browserFill from '@iconify/icons-eva/browser-fill';
import clipboardFill from '@iconify/icons-eva/clipboard-fill';
import mapFill from '@iconify/icons-eva/map-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import calendarFill from '@iconify/icons-eva/calendar-fill';
import messageSquareFill from '@iconify/icons-eva/message-square-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'forms',
    path: '/dashboard/forms',
    icon: getIcon(clipboardFill)
  },
  {
    title: 'tables',
    path: '/dashboard/tables',
    icon: getIcon(browserFill)
  },
  {
    title: 'maps',
    path: '/dashboard/maps',
    icon: getIcon(mapFill)
  },
  {
    title: 'calendars',
    path: '/dashboard/calendars',
    icon: getIcon(calendarFill)
  },
  {
    title: 'messaging',
    path: '/dashboard/messaging',
    icon: getIcon(messageSquareFill)
  },
  {
    title: 'login',
    path: '/login',
    icon: getIcon(lockFill)
  },
  {
    title: 'register',
    path: '/register',
    icon: getIcon(personAddFill)
  },
  {
    title: 'Not found',
    path: '/404',
    icon: getIcon(alertTriangleFill)
  }
];

export default sidebarConfig;
